
export default {
  data() {
    return {
      isCalendlyActive: false,
      linkCalendly: null,
      isDesktop: false,
      isTablet: false,
      heroItems: [
        "Un véritable savoir-faire",
        "Les meilleurs experts",
        "Des offres flexibles",
      ],
      heroJobs: ["commerçants", "artisans", "indépendants"],
      indexJob: 0,
      successItems: [
        {
          title: "Visibilité",
          text: "Devenez plus visible pour attirer plus de clients.",
          img: require("~/assets/img/picto-visible.svg"),
        },
        {
          title: "Résultats",
          text: "Obtenez des résultats rapides et mesurables.",
          img: require("~/assets/img/picto-stats.svg"),
        },
        {
          title: "Rapidité",
          text: "Gagnez du temps grâce à une équipe d'experts dédiée.",
          img: require("~/assets/img/picto-time.svg"),
        },
      ],
      offerItems: [
        {
          title: "Site web",
          price: "89€",
          perMonth: true,
          text: `Boostez votre activité grâce à <br class="hidden lg:block"> votre site internet clé en main.`,
          link: "web",
          img: "offer-web.webp",
          alt: "Exemple de site internet",
        },
        {
          title: "Réseaux sociaux",
          price: "189€",
          perMonth: true,
          text: `Développez votre notoriété et gagnez en <br class="hidden lg:block"> crédibilité pour attirer de nouveaux clients.`,
          link: "gestion-rs",
          img: "offer-cm.png",
          alt: "Exemples de posts Facebook ou Instagram",
        },
        {
          title: "Publicité Google",
          price: "129€",
          perMonth: true,
          text: `Générez plus de contacts en <br class="hidden lg:block"> passant de visible à incontournable.`,
          link: "seo",
          img: "offer-seo.webp",
          alt: "Landing page campagne Google Ads",
        },
        {
          title: "Graphisme",
          price: "499€",
          perMonth: false,
          text: `Différenciez-vous et marquez les esprits de vos <br class="hidden lg:block"> clients avec une image professionnelle.`,
          link: "graphism",
          img: "offer-graphism.webp",
          alt: "Exemple de cartes de visite et logo ALEO",
        },
      ],
      partners: [
        {
          img: "logo-g-partners.png",
          alt: "ALEO - Google Partner",
        },
        {
          img: "logo-facebook.png",
          alt: "Partenaire Facebook",
        },
        {
          img: "logo-instagram.png",
          alt: "Instagram Partenaire",
        },
        {
          img: "logo-france-num.png",
          alt: "Activateur France Num",
        },
        {
          img: "logo-ma.png",
          alt: "Chambre des métiers et de l'artisanat",
        },
      ],
      webNews: [
        {
          link: "https://www.lefigaro.fr/services/aleo-l-agence-web-n01-dans-la-creation-de-sites-internet-pour-les-tpe-pme-20210716",
          img: require("~/assets/img/logo-le-figaro.svg"),
          alt: "logo le figaro",
        },
        {
          link: "https://www.bfmtv.com/economie/professionnels/aleo-l-agence-de-communication-pour-tpe-pme-qui-va-propulser-votre-activite_AN-202107290333.html",
          img: require("~/assets/img/logo-bfm-business.svg"),
          alt: "logo BFM Business",
        },
        {
          link: "https://www.challenges.fr/entreprise/aleo-l-agence-de-communication-100-dediee-aux-tpe-pme_773971",
          img: require("~/assets/img/logo-challenges.svg"),
          alt: "logo Challenges",
        },
        {
          link: "https://www.latribune.fr/supplement/comment-l-agence-web-aleo-souhaite-booster-la-presence-en-ligne-des-tpe-pme-890514.html",
          img: require("~/assets/img/logo-la-tribune.svg"),
          alt: "logo la tribune",
        },
        {
          link: "https://www.forbes.fr/brandvoice/10000-entreprises-conquises-pour-aleo-lagence-web-n1-des-tpe-pme/",
          img: require("~/assets/img/logo-forbes.svg"),
          alt: "logo Forbes",
        },
      ],
      reviews: [
        {
          text: "Merci pour votre réactivité. À chaque demande, vous répondez présent, que ce soit par téléphone ou par mail, et vous trouvez une solution rapidement.",
          author: "Virginie Caboche",
        },
        {
          text: "Je recommande, cette agence de communication ! Ils font du bon travail, Malik est très professionnel et effectue un bon suivi.",
          author: "QUALIT",
        },
        {
          text: "Agence à recommander, professionnalisme et réactivité au TOP.",
          author: "TGL",
        },
        {
          text: "Très professionnel, très réactif, je recommande vivement à 100 %",
          author: "CHAUFF'TAJ",
        },
      ],

      faq: [
        {
          question:
            "Pourquoi choisir une agence de communication telle que ALEO ?",
          answer: `<p class="pb-4">L'importance du choix d'une <strong>agence de communication</strong> dans le processus de croissance de votre entreprise est indéniable.</p>
  <p class="pb-4">ALEO est votre partenaire stratégique dédié à <strong>accroître votre visibilité</strong>, consolider <strong>votre image</strong> de marque et à améliorer votre positionnement <strong>concurrentiel</strong>.</p>
  <p class="pb-4">ALEO vous offre un accompagnement complet dans toutes vos initiatives de communication. Notre agence réunit une diversité de professionnels chefs de projet, graphistes, rédacteurs, référenceurs, webdesigners, et community managers qui collaborent étroitement pour élaborer et exécuter des <strong>stratégies de communication</strong> complètes et efficaces. </p>
  <p class="pb-4">En conclusion, choisir ALEO vous permettra de tirer profit de l'expertise et du savoir-faire d’une agence de communication. Nous pouvons vous aider à concevoir des <strong>stratégies de communication</strong> efficaces et à accroître votre <strong>notoriété</strong> et le <strong>développement de votre entreprise.</strong></p>
  <p>Donc, si votre objectif est d'améliorer votre visibilité, d’attirer plus de clients et de les fidéliser, faites appel à une <strong>agence de communication</strong> telle que ALEO.</p>`,
          isCollapsed: false,
        },
        //       {
        //         question: "Quels sont les tarifs de l’agence de communication ALEO ?",
        //         answer: `<p class="pb-4">Les <strong>tarifs de l’agence de communication ALEO</strong> sont conçus pour s'adapter à une grande variété de besoins en matière de <strong>communication</strong> et de marketing.</p>
        // <p class="pb-4">Nos services englobent un large éventail d'expertises, comprenant la <strong>conception de sites internet</strong>, le <strong>référencement naturel</strong>, le <strong>référencement payant</strong>, la gestion de vos réseaux sociaux ou encore la création de visuels (logos, cartes de visite, etc.).</p>
        // <p>Pour une transparence totale, nous avons rendu nos <strong>tarifs</strong> accessibles à tous sur notre page dédiée.  Si vous avez une question relative au contenu de nos offres, nous vous invitons à prendre contact avec un de nos conseillers commerciaux.</p>`,
        //         isCollapsed: false,
        //       },
        {
          question:
            "Pourquoi être accompagné par une agence de communication digitale ?",
          answer: `<p class="pb-4">Dans le contexte actuel, la présence numérique s'est révélée être un élément crucial dans la réussite de toute entreprise.</p>
          <p class="pb-4">Une <strong>agence de communication digitale</strong> joue un rôle majeur dans le développement de votre entreprise en l’aidant à atteindre ses objectifs en ligne. En collaborant avec une agence <strong>experte</strong> dans le domaine numérique comme ALEO, vous avez l'opportunité de renforcer <strong>votre image</strong> de marque, d'améliorer votre <strong>e-réputation</strong> et d'acquérir de <strong>nouveaux clients.</strong></p>
          <p class="pb-4">Les <strong>agences de communication digitale</strong> visent à aider les entreprises à diffuser un message cohérent et efficace <strong>sur le Web</strong> tout en élargissant leur présence numérique à grande échelle. Pour ce faire, ces agences élaborent des <strong>stratégies de communication</strong> numérique sur mesure, qui incluent l'<strong>identité visuelle</strong>, la <strong>gestion de contenu</strong>, le <strong>référencement SEO</strong>, les publicités payantes et les réseaux sociaux.</p>
          <p class="pb-4">Une <strong>agence de communication comme ALEO</strong> peut également vous accompagner dans la création de votre site web, en créant une vitrine attrayante et esthétique en ligne qui vous permettra d’attirer plus de clients. </p>
          <p class="pb-4">Ce dernier est essentiel dans votre <strong>communication digitale</strong> et dans votre stratégie d’acquisition de <strong>nouveaux clients</strong>. La conception d’un <strong>site internet ALEO</strong> se fait dans une perspective de <strong>fidélisation</strong> et de <strong>conversion</strong>, en créant des contenus <strong>pertinents</strong> qui répondent aux besoins et aux attentes de vos clients. </p>
          <p class="pb-4">En somme, <strong>collaborer</strong> avec une <strong>agence de communication digitale</strong> apportera une  multitude d'avantages à votre entreprise, en termes de <strong>notoriété</strong>, de visibilité <strong>sur le Web</strong>, d'acquisition de <strong>nouveaux clients</strong> et de <strong>fidélisation</strong>. </p>
  <p>Vous avez donc tout à gagner en choisissant une <strong>agence de communication spécialisée</strong> pour vous accompagner dans la <strong>transformation digitale</strong> de votre entreprise et réaliser vos objectifs de communication et de marketing.</p>`,
          isCollapsed: false,
        },
        {
          question: "Quelles sont les valeurs de ALEO ?",
          answer: `<p class="pb-4">Depuis sa création en 2021, la mission de l’agence de communication ALEO est d’accompagner tous les entrepreneurs dans leur communication pour faire de chaque projet une réussite.</p>
  <p class="pb-4">Nous sommes guidés par 4 valeurs fondamentales : </p>
  <ul>
    <li> - La liberté : Offrir la possibilité à chaque entrepreneur de développer son projet selon ses propres aspirations.</li>
    <li> - L’égalité : Rendre la communication accessible à tous les entrepreneurs.</li>
    <li> - L’empathie : Guider les entrepreneurs dans leur projet en comprenant parfaitement leurs enjeux.</li>
    <li> - La performance : Générer des résultats mesurables et tangibles à chaque action. </li>
  </ul>`,
          isCollapsed: false,
        },
        {
          question: "Comment fonctionne l’agence de communication ALEO ?",
          answer: `<p class="pb-4">Chez ALEO, chaque projet de communication commence par un entretien téléphonique avec un conseiller commercial. Ce rendez-vous aura pour objectif de vous proposer les meilleures solutions de communication en fonction de vos besoins, de vos objectifs et de votre budget.</p>
          <p class="pb-4">Ensuite, une fois votre projet démarré, un chargé de projet dédié vous contactera pour aller plus en détails dans la concrétisation de votre projet de communication.
Par exemple, si vous souhaitez passer par ALEO pour la création de votre site web, un webdesigner dédié vous contactera afin d’en apprendre plus sur vos attentes (design du site internet, contenus à mettre en avant, stratégie de référencement sur Google, etc.).</p>
  <p>Nous faisons en sorte de comprendre au maximum vos attentes pour vous permettre d’avoir une stratégie de communication sur mesure et efficace.</p>`,
          isCollapsed: false,
        },
        {
          question: "Combien coûte une agence de communication ?",
          answer: `<p class="pb-4">Le <strong>prix d’une agence de communication</strong> varie selon les services proposés, la taille de l’agence, et les entreprises qu’elles accompagnent. La majorité des agences de communication effectuent des devis pour les projets de communication des entrepreneurs. <strong>Les tarifs d’une agence de communication</strong> classique varient généralement de 500€ à 5 000€ par mois.</p><p class="pb-4">Chez ALEO, nous cherchons à proposer des prix fixes à nos clients et des prestations à la carte afin de leur permettre une meilleure visibilité sur leur budget à investir. Nos offres <strong>commencent à partir de 89 € HT/mois</strong> pour la création de site web.</p>`,
          isCollapsed: false,
        },
        {
          question: "Comment choisir son agence de communication ?",
          answer: `<p class="pb-4">Pour bien <strong>choisir votre agence de communication</strong>, il faut d’abord comprendre vos besoins en termes de communication. Avez-vous des problèmes à <strong>attirer des clients</strong>, à <strong>vendre</strong>, à <strong>être crédible</strong> ? Une bonne <strong>agence de communication</strong> pourra vous proposer différentes solutions pour résoudre ces problèmes, mais cela nécessite une bonne connaissance de votre entreprise et de vos besoins.</p><p class="pb-4">Chaque agence a un positionnement différent et sera plus ou moins adaptée à leurs typologies d’entrepreneurs. Chez ALEO, nous nous adressons aux <strong>TPE</strong> souhaitant avoir une communication efficace en investissant le meilleur budget possible. Commerçants, artisans et indépendants, nous accompagnons les professionnels ayant de faibles budgets qui souhaitent faire connaître leur entreprise.</p>`,
          isCollapsed: false,
        },
        {
          question: "Comment faire un brief à une agence de communication ?",
          answer: `<p class="pb-4">Pour <strong>faire un brief à une agence de communication</strong>, il faut être le plus précis possible dans votre demande. Si vous désirez un service en particulier, expliquez dans votre <strong>brief</strong> pourquoi vous pensez que c’est la meilleure solution pour résoudre vos problèmes de communication.</p><p class="pb-4">En détaillant au maximum votre brief, une bonne <strong>agence de communication</strong> pourra prendre de l’avance et vous proposer directement un devis précis, voire vous proposer d’autres solutions mieux adaptées à vos besoins. Si vous faites un brief à une agence de communication dans le but d’organiser un rendez-vous, mettez en avant les horaires auxquels vous êtes généralement disponibles et combien de temps vous souhaitez accorder au RDV.</p>`,
          isCollapsed: false,
        },
        {
          question: "Comment fonctionne une agence de communication ?",
          answer: `<p class="pb-4">Le <strong>fonctionnement d’une agence de communication</strong> varie d’une agence à l’autre. Certaines agences de communication fonctionnent sur la base de devis, et d’autres proposent des prix fixes. Une solution permet plus de souplesse, et l’autre permet de donner plus de structure aux différents services proposés.</p><p class="pb-4"><strong>Chez ALEO</strong>, nous fonctionnons avec des <strong>offres à la carte clés en main</strong>. Nos équipes s’occupent de tout pour leurs clients à des prix fixes. Les tarifs de nos prestations sont mensualisés pour permettre à un maximum d’entrepreneurs d’envisager la communication comme une solution accessible qui génère des résultats.</p><p class="pb-4">Chez ALEO, tout se fait à distance. Un chargé de projet sera attribué à votre projet et vous accompagnera dans la réussite de votre projet de communication, et pourra vous conseiller au besoin pour améliorer certains aspects de votre marketing.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Quels sont les avantages de faire appel à une agence de communication ?",
          answer: `<p class="pb-4">Faire appel à une agence de communication a beaucoup d’avantages pour les entrepreneurs. Tout d’abord, cela permet de faire appel à des professionnels et d’avoir des <strong>prestations de qualité</strong>. Créer son site internet, gérer ses réseaux sociaux, créer du contenu, faire des campagnes publicitaires, toutes ces actions requièrent des compétences techniques précises. Une <strong>agence de communication</strong> permet d’apporter ces compétences aux entrepreneurs pour les rendre visibles.</p><p class="pb-4">Passer par une agence de communication permet également de <strong>gagner du temps</strong>. La communication étant chronophage, la déléguer à une entreprise permet de rester focalisé sur son cœur d’activité.</p><p class="pb-4">Pour ces raisons, faire appel à une agence de communication permet aux entrepreneurs de <strong>maximiser leur retour sur investissement</strong> et d’<strong>accroître leur visibilité</strong> !</p>`,
          isCollapsed: false,
        },
      ],
    };
  },

  head() {
    return {
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content:
            "L'agence de communication dédiée aux artisans, commerçants et indépendants.",
        },
      ],
    };
  },

  computed: {
    heroItemResponsive() {
      return this.isDesktop
        ? this.heroItems
        : this.heroItems.concat(this.heroItems).concat(this.heroItems);
    },
  },

  mounted() {
    this.watchMedia();
    window
      .matchMedia("(min-width: 1024px)")
      .addEventListener("change", (event) => {
        event.matches ? (this.isDesktop = true) : (this.isDesktop = false);
      });
    this.alternateJobs();
    this.playVideo();
    // this.playVideoStarter();
  },

  methods: {
    alternateJobs() {
      setInterval(() => {
        this.indexJob++;
        if (this.indexJob >= this.heroJobs.length) {
          this.indexJob = 0;
        }
      }, 1500);
    },

    playVideo() {
      const io = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.isTablet) {
              entry.target.src = require("~/assets/video/video-aleo-home.mp4");
            } else {
              entry.target.src = require("~/assets/video/video-aleo-home-mobile.mp4");
            }
            entry.target.play();
            io.unobserve(entry.target);
          }
        });
      });

      io.observe(this.$refs.video);
    },

    // playVideoStarter() {
    //   const io = new IntersectionObserver((entries) => {
    //     entries.forEach((entry) => {
    //       if (entry.isIntersecting) {
    //         if (this.isTablet) {
    //           entry.target.src = require("~/assets/video/video-aleo-starter.mp4");
    //         } else {
    //           entry.target.src = require("~/assets/video/video-aleo-starter-mobile.mp4");
    //         }
    //         entry.target.play();
    //         io.unobserve(entry.target);
    //       }
    //     });
    //   });

    //   io.observe(this.$refs.videoStarter);
    // },

    activeCalendly(
      link = "https://calendly.com/aleo-alpha/rendez-vous-decouverte-aleo/"
    ) {
      this.linkCalendly = link;
      this.isCalendlyActive = false;
      this.$nextTick(() => {
        this.isCalendlyActive = true;
      });
    },

    watchMedia() {
      window.matchMedia("(min-width: 1024px)").matches
        ? (this.isDesktop = true)
        : (this.isDesktop = false);

      window.matchMedia("(min-width: 768px)").matches
        ? (this.isTablet = true)
        : (this.isTablet = false);

      if (this.isTablet) {
        this.$refs.video.setAttribute("poster", "/image/thumb-video-home.jpg");
      } else {
        this.$refs.video.setAttribute(
          "poster",
          "/image/thumb-video-home-mobile.jpg"
        );
      }
    },
  },
};
